import * as React from "react"
import {Link} from "gatsby"

const NotFoundPage = () => {
    return (
        <main className="NotFound">
            <p1 className="Text-heading">
                Missing Sector
            </p1>
            <Link className="Link--light" to="/">Travel Home</Link>.
        </main>
    )
}

export default NotFoundPage
